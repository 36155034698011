/*body {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}*/

.overflow-y-scroll {
	-webkit-overflow-scrolling: touch;
}

svg {
	display: block;
}

* {
	outline: none !important;
}

.dropdown-component {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.modal {
	max-height: 100vh;
}

.more-filters {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.height-back-bar {
	height: 56px;
}

.f8 {
	font-size: 0.5rem;
}

.right-0-important {
	right: 0 !important;
}

@media screen and (min-width: 30em) {
	.dropdown-component {
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
		position: absolute;
		max-height: calc(100vh - 166px);
		margin-top: 1rem;
		border-radius: 0.25rem;
		box-shadow: rgba(66, 66, 66, 0.1) 0px 2px 5px 0px,
			rgba(0, 0, 0, 0.0666) 0px 1px 1px 0px;
		border-style: solid;
		border-width: 1px;
		border-color: #eee;
	}

	.more-filters {
		position: absolute;
		height: calc(100vh - 84px - 66px);
		top: 67px;
	}

	.height-back-bar {
		height: 83px;
	}

	.per-night {
		font-size: 0.75rem;
	}
}

.h05 {
	height: 0.5rem;
}

.w05 {
	width: 1rem;
}

.map {
	position: fixed;
	padding-right: 1rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	height: calc(100vh - 83px);
	width: calc(100vw - 384px - 192px - 32px - 256px);
}

.hotel-map {
	position: fixed;
	padding-right: 1rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	height: calc(100vh - 83px);
	width: 40%;
}

.map-cards {
	height: calc(100vh - 268px - 85px - 67px);
	width: 100%;
}

.gradient {
	background: linear-gradient(
		to bottom right,
		rgba(90, 200, 250, 0.75),
		rgba(88, 86, 214, 0.75),
		rgba(255, 45, 85, 0.75)
	);
	background-size: 400% 400%;
	-webkit-animation: Gradient 15s linear infinite;
	        animation: Gradient 15s linear infinite;
}

@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 100% 100%;
	}
	100% {
		background-position: 0% 0%;
	}
}

@keyframes Gradient {
	0% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 100% 100%;
	}
	100% {
		background-position: 0% 0%;
	}
}

/* Ripple magic */
.ripple {
	position: relative;
	overflow: hidden;
}

.ripple:after {
	content: "";
	mix-blend-mode: screen;
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -4rem;
	width: 8rem;
	height: 8rem;
	background-color: rgba(255, 255, 255, 0.5);
	opacity: 0;
	border-radius: 100%;
	-webkit-transform-origin: 50% 50%;
	        transform-origin: 50% 50%;
}

@-webkit-keyframes ripple {
	0% {
		-webkit-transform: scale(0);
		        transform: scale(0);
		opacity: 1;
		background-color: rgba(255, 255, 255, 0.5);
	}
	75% {
		background-color: rgba(255, 255, 255, 0);
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(4);
		        transform: scale(4);
	}
}

@keyframes ripple {
	0% {
		-webkit-transform: scale(0);
		        transform: scale(0);
		opacity: 1;
		background-color: rgba(255, 255, 255, 0.5);
	}
	75% {
		background-color: rgba(255, 255, 255, 0);
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(4);
		        transform: scale(4);
	}
}

.ripple::after {
	-webkit-animation: ripple 2s ease-out;
	        animation: ripple 2s ease-out;
	-webkit-animation-delay: 0s;
	        animation-delay: 0s;
}

.gm-style-mtc {
	display: none !important;
}

.book-button-padding {
	padding-bottom: 68px;
}

.no-scroll-bar {
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* IE 10+ */
}
.no-scroll-bar::-webkit-scrollbar {
	/* WebKit */
	width: 0;
	height: 0;
}

.book-button {
	max-height: 50px;
}

@media (min-width: 30em) {
	.book-button-padding {
		padding-bottom: 0;
	}

	/*.small-map {
		position: fixed;
		padding-right: 1rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
		width: 40%;
		height: calc(100vh - 84px);
	}

	.large-map {
		position: fixed;
		padding-right: 1rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
		width: calc(100vw - 384px - 192px - 32px - 256px);
		height: calc(100vh - 66px);
	}*/

	.book-button {
		max-height: none;
	}
}

.terms p {
	margin: 0;
}

.terms p b {
	font-weight: 600;
	display: block;
	/*display: none;*/
}

.terms p br {
	/*display: none;*/
}

.cursor {
	cursor: default;
}

.aspect-ratio--2-39x1 {
	padding-bottom: 41.84%;
}

.bg-white-gradient {
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 0),
		rgba(255, 255, 255, 1),
		rgba(255, 255, 255, 1)
	);
}

.min-h-100 {
	min-height: 100vh;
}

.link {
	-webkit-animation: none;
	        animation: none;
}

.br05 {
	border-radius: 0.125rem;
}

.blue {
	color: rgb(0, 122, 255);
}

.bright-green {
	color: rgb(76, 217, 100);
}

.pink {
	color: rgb(255, 45, 85);
}

.bg-pink {
	background-color: rgb(255, 45, 85);
}

.bg-near-white {
	background-color: rgb(250, 250, 250);
}

.shadow {
	/*box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);*/
	box-shadow: rgba(66, 66, 66, 0.2) 0px 2px 5px 0px,
		rgba(0, 0, 0, 0.0666) 0px 1px 1px 0px;
}

.maps-shadow {
	box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
}

.top-100 {
	top: 100%;
}

.pv05 {
	padding-top: 0.125rem;
	padding-bottom: 0.125rem;
}

.mh05 {
	margin-left: 0.125rem;
	margin-right: 0.125rem;
}

.man3 {
	margin: -1rem;
}

.man2 {
	margin: -0.5rem;
}

.mhn2 {
	margin-left: -0.5rem;
	margin-right: -0.5rem;
}

.mhn3 {
	margin-left: -1rem;
	margin-right: -1rem;
}

.mtn3 {
	margin-top: -1rem;
}

.min-w1 {
	min-width: 1rem;
}

.h1-5 {
	height: 1.5rem;
}

.w1-5 {
	width: 1.5rem;
}

.h2-5 {
	height: 3rem;
}

.w5-5 {
	min-width: 24rem;
	max-width: 24rem;
}

.mw5-5 {
	max-width: 24rem;
}

.h-100vw-s {
	height: 100vw;
}

.h4-5 {
	height: 12rem;
}

.bg-gradient {
	background: linear-gradient(to bottom, rgb(0, 122, 255), white);
}

.blue {
	color: rgb(0, 122, 255);
}

.bg-blue {
	background-color: rgb(0, 122, 255);
}

.bg-blue-50 {
	background-color: rgba(0, 122, 255, 0.4);
}

.hover-bg-dark-blue:hover {
	background-color: rgb(0, 122, 255);
}

.b--blue {
	border-color: rgb(0, 122, 255);
}

.hover-b--dark-blue:hover {
	border-color: rgb(0, 103, 216);
}

.main-bg {
	background: linear-gradient(to bottom right, #ffffff, #eee);
}

.main-bg-gradient-reverse {
	background: linear-gradient(to top right, #ffffff, #eee);
}

@media screen and (min-width: 64em) {
	.mw5-5-l {
		max-width: 24rem;
	}

	.mtn3-l {
		margin-top: -1rem;
	}
}

@media screen and (min-width: 30em) {
	.h-100vw-s {
		height: auto;
	}

	.mw6-5-ns {
		max-width: 38rem;
	}

	.mw5-5-ns {
		max-width: 24rem;
	}

	.mw4-5-ns {
		max-width: 12rem;
	}

	.w5-5-ns {
		width: 24rem;
	}

	.h4-5-ns {
		height: 12rem;
	}

	.bg-transparent-ns {
		background-color: transparent;
	}

	.blue-ns {
		color: #007aff;
	}

	.left-auto-ns {
		left: auto;
	}

	.right-auto-ns {
		right: auto;
	}

	.modal {
		max-height: calc(100vh - 64px);
	}

	.main-bg {
		background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
			url("/bg.jpg"), rgb(3, 12, 15);
		background-position: center 5%;
	}

	.main-bg-gradient {
		background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0)),
			linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	}

	.text-shadow-ns {
		text-shadow: rgba(66, 66, 66, 0.1) 0px 2px 5px 0px,
			rgba(0, 0, 0, 0.0666) 0px 1px 1px 0px;
	}

	.shadow-ns {
		box-shadow: rgba(66, 66, 66, 0.1) 0px 2px 5px 0px,
			rgba(0, 0, 0, 0.0666) 0px 1px 1px 0px;
	}

	.white-ns {
		color: white;
	}
}

@media screen and (max-width: 64em) {
	.mw5-5-nl {
		max-width: 24rem;
	}
}

@media screen and (min-width: 96em) {
	.h5-xl {
		height: 16rem;
	}
}

/*// NOTE: the order of these styles DO matter*/

.DayPicker {
	box-shadow: none;
}

.DateRangePicker_picker {
	z-index: 9999;
}

.DayPickerNavigation_button {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.DateInput_input {
	font-weight: 700;
	font-size: 1rem;
	color: black;
}

.DateInput_input__focused {
	border-bottom: none;
}

.CalendarMonth_caption {
	font-weight: 500;
	color: black;
}

/*// Will edit everything selected including everything between a range of dates*/
.CalendarDay__selected_span {
	background: rgba(0, 122, 255, 1);
	color: white;
	border: 1px solid #eee;
}

/*// Will edit selected date or the endpoints of a range of dates*/
.CalendarDay__selected {
	background: rgb(0, 122, 255);
	color: white;
	border: 1px solid #eee;
}

/*// Will edit when hovered over. _span style also has this property*/
.CalendarDay__selected:hover {
	background: rgb(0, 122, 255);
	color: white;
}

/*// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date*/
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
	background: green;
}

