/*// NOTE: the order of these styles DO matter*/

.DayPicker {
	box-shadow: none;
}

.DateRangePicker_picker {
	z-index: 9999;
}

.DayPickerNavigation_button {
	display: flex;
	justify-content: center;
	align-items: center;
}

.DateInput_input {
	font-weight: 700;
	font-size: 1rem;
	color: black;
}

.DateInput_input__focused {
	border-bottom: none;
}

.CalendarMonth_caption {
	font-weight: 500;
	color: black;
}

/*// Will edit everything selected including everything between a range of dates*/
.CalendarDay__selected_span {
	background: rgba(0, 122, 255, 1);
	color: white;
	border: 1px solid #eee;
}

/*// Will edit selected date or the endpoints of a range of dates*/
.CalendarDay__selected {
	background: rgb(0, 122, 255);
	color: white;
	border: 1px solid #eee;
}

/*// Will edit when hovered over. _span style also has this property*/
.CalendarDay__selected:hover {
	background: rgb(0, 122, 255);
	color: white;
}

/*// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date*/
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
	background: green;
}
